import { CloseIcon } from '@chakra-ui/icons';
import { Modal, ModalContent, ModalBody, Button, Box, Text } from '@chakra-ui/react';
import React from 'react';
import Delete from '../../Icons/Delete';
import { DeleteClientModalProps } from '../../interfaces/components';

function DeleteInvestmentModal({ onClose, isOpen, clientToDelete, onDeleteClick }: DeleteClientModalProps) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      {/* <ModalOverlay /> */}
      <ModalContent
        width={['90vw', '380px', '404px', '404px', '404px']}
        borderRadius="20px"
        padding={['24px', '36px', '36px']}
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <ModalBody padding="0px" display="flex" flexDirection="column" gap={5} position="relative">
          <CloseIcon top="0" right="0" position="absolute" boxSize={4} onClick={onClose} cursor="pointer" />
          <Text fontFamily="Montserrat" fontWeight="700" fontSize="20px">
            Delete Investment
          </Text>
          <Text fontFamily="Montserrat" fontWeight="500" fontSize="14px">
            Are you sure you want to delete this Investment? You cant undo this action.
          </Text>
          <Box display="flex" gap="14px" width="100%" alignItems="center" justifyContent="center">
            <Button
              width="30%"
              borderRadius="14px"
              border="1px solid #8C8C8C"
              background="#FFF"
              fontFamily="Montserrat"
              fontWeight={600}
              fontSize="14px"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              whiteSpace={['break-spaces', 'break-spaces', 'nowrap']}
              leftIcon={<Delete boxSize={4} />}
              color="#DC3545"
              type="submit"
              width="70%"
              borderRadius="12px"
              background="#FBE9EB"
              fontWeight={600}
              fontFamily="Montserrat"
              fontSize="14px"
              onClick={() => clientToDelete && onDeleteClick()}
              _hover={{
                color: 'white',
                bg: '#0FBF75',
              }}
            >
              Delete investment
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DeleteInvestmentModal;
