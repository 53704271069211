import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function LogoutLogoDrawer(props: IconProps) {
  return (
    <Icon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_367_7152"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_367_7152)">
        <path
          d="M3.98081 15.375C3.60194 15.375 3.28125 15.2438 3.01875 14.9813C2.75625 14.7188 2.625 14.3981 2.625 14.0192V3.98081C2.625 3.60194 2.75625 3.28125 3.01875 3.01875C3.28125 2.75625 3.60194 2.625 3.98081 2.625H8.44481C8.60431 2.625 8.73794 2.67887 8.84569 2.78662C8.95331 2.89425 9.00713 3.02788 9.00713 3.1875C9.00713 3.34712 8.95331 3.48075 8.84569 3.58838C8.73794 3.69613 8.60431 3.75 8.44481 3.75H3.98081C3.92306 3.75 3.87019 3.77406 3.82219 3.82219C3.77406 3.87019 3.75 3.92306 3.75 3.98081V14.0192C3.75 14.0769 3.77406 14.1298 3.82219 14.1778C3.87019 14.2259 3.92306 14.25 3.98081 14.25H8.44481C8.60431 14.25 8.73794 14.3039 8.84569 14.4116C8.95331 14.5192 9.00713 14.6529 9.00713 14.8125C9.00713 14.9721 8.95331 15.1058 8.84569 15.2134C8.73794 15.3211 8.60431 15.375 8.44481 15.375H3.98081ZM13.2129 9.5625H7.38469C7.22506 9.5625 7.09137 9.50863 6.98362 9.40088C6.876 9.29325 6.82219 9.15963 6.82219 9C6.82219 8.84037 6.876 8.70675 6.98362 8.59912C7.09137 8.49137 7.22506 8.4375 7.38469 8.4375H13.2129L11.7707 6.99525C11.6668 6.89138 11.6137 6.76469 11.6113 6.61519C11.6089 6.46569 11.6621 6.33275 11.7707 6.21637C11.8793 6.1 12.0111 6.03987 12.1659 6.036C12.3207 6.03225 12.4563 6.0885 12.5726 6.20475L14.8933 8.52544C15.0288 8.66106 15.0966 8.81925 15.0966 9C15.0966 9.18075 15.0288 9.33894 14.8933 9.47456L12.5726 11.7952C12.4611 11.9067 12.3287 11.9618 12.1753 11.9603C12.0219 11.9589 11.8871 11.9 11.7707 11.7836C11.6621 11.6672 11.6097 11.5336 11.6136 11.3827C11.6173 11.2318 11.6736 11.1019 11.7823 10.9933L13.2129 9.5625Z"
          fill="#1A1A1A"
        />
      </g>
    </Icon>
  );
}
