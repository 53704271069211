import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function ClientsLogo(props: IconProps) {
  return (
    <Icon width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_152_1047"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="35"
        height="34"
      >
        <rect x="0.5" width="34" height="34" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_152_1047)">
        <path
          d="M19.271 11.4629V6.24565C19.271 5.8759 19.3941 5.56895 19.6404 5.32481C19.8867 5.08044 20.1917 4.95825 20.5556 4.95825H28.2629C28.627 4.95825 28.9311 5.08044 29.1753 5.32481C29.4196 5.56895 29.5418 5.8759 29.5418 6.24565V11.4629C29.5418 11.8324 29.4187 12.1392 29.1724 12.3834C28.9262 12.6277 28.6211 12.7499 28.2573 12.7499H20.5499C20.1858 12.7499 19.8817 12.6277 19.6376 12.3834C19.3932 12.1392 19.271 11.8324 19.271 11.4629ZM5.4585 16.4333V6.2329C5.4585 5.87165 5.58163 5.56895 5.82789 5.32481C6.07416 5.08044 6.37921 4.95825 6.74306 4.95825H14.4504C14.8145 4.95825 15.1186 5.08044 15.3628 5.32481C15.6071 5.56919 15.7293 5.872 15.7293 6.23325V16.4336C15.7293 16.7949 15.6062 17.0976 15.3599 17.3417C15.1137 17.5861 14.8086 17.7083 14.4448 17.7083H6.73739C6.37331 17.7083 6.0692 17.5861 5.82506 17.3417C5.58068 17.0973 5.4585 16.7945 5.4585 16.4333ZM19.271 27.7666V17.5662C19.271 17.205 19.3941 16.9023 19.6404 16.6581C19.8867 16.4138 20.1917 16.2916 20.5556 16.2916H28.2629C28.627 16.2916 28.9311 16.4138 29.1753 16.6581C29.4196 16.9025 29.5418 17.2053 29.5418 17.5666V27.7669C29.5418 28.1282 29.4187 28.4309 29.1724 28.675C28.9262 28.9194 28.6211 29.0416 28.2573 29.0416H20.5499C20.1858 29.0416 19.8817 28.9194 19.6376 28.675C19.3932 28.4307 19.271 28.1278 19.271 27.7666ZM5.4585 27.7542V22.537C5.4585 22.1674 5.58163 21.8606 5.82789 21.6165C6.07416 21.3721 6.37921 21.2499 6.74306 21.2499H14.4504C14.8145 21.2499 15.1186 21.3721 15.3628 21.6165C15.6071 21.8606 15.7293 22.1674 15.7293 22.537V27.7542C15.7293 28.1239 15.6062 28.4309 15.3599 28.675C15.1137 28.9194 14.8086 29.0416 14.4448 29.0416H6.73739C6.37331 29.0416 6.0692 28.9194 5.82506 28.675C5.58068 28.4309 5.4585 28.1239 5.4585 27.7542ZM7.5835 15.5833H13.6043V7.08325H7.5835V15.5833ZM21.396 26.9166H27.4168V18.4166H21.396V26.9166ZM21.396 10.6249H27.4168V7.08325H21.396V10.6249ZM7.5835 26.9166H13.6043V23.3749H7.5835V26.9166Z"
          fill="#0FBF95"
        />
      </g>
    </Icon>
  );
}
