import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function Down(props: IconProps) {
  return (
    <Icon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_130_26365"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_130_26365)">
        <path d="M12 15.5L7 10.5H17L12 15.5Z" fill="#1A1A1A" />
      </g>
    </Icon>
  );
}
