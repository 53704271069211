import {
  Box,
  useToast,
  Spinner,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useAppStore, AppStoreState } from '../../store';
import { deleteInvestmentById, getInvestments, getPayoutsByInvestmentId } from '../../api/InvestorApis/investments';
import { InvestmentPayoutArray, InvestmentResultsArray } from '../../interfaces/components';
import InvestmentLogo from '../../Icons/InvestmentLogo';
import { AddIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import DeleteInvestmentModal from './DeleteInvestmentModal';
import Delete from '../../Icons/Delete';
import ManageInvestmentModal from './ManageInvestmentModal';
import Countdown from '../Countdowm';
import ClaimTokenModal from '../claimToken/ClaimTokenModal';

export default function Investment() {
  // const navigate = useNavigate();
  const [payoutsLoading, setPayoutsLoading] = useState(false);
  const toast = useToast();
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const investmentsList = useAppStore((state: AppStoreState) => state.investmentsList);
  const setInvestmentsList = useAppStore((state: AppStoreState) => state.setInvestmentsList);
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const itemsPerPage = investmentsList && investmentsList?.results?.length > 0 && investmentsList?.results?.length;
  const totalPages = itemsPerPage && investmentsList && investmentsList?.total_pages;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: deleteModalOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();
  const [investmentToEdit, setInvestmentToEdit] = useState<any>(null);
  const [investmentToDelete, setInvestmentToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [investmentId, setInvestmentId] = useState<number | undefined>(undefined);
  const { isOpen: manageModalOpen, onOpen: openManageModal, onClose: closeManageModal } = useDisclosure();
  const [investmentPayout, setInvestmentPayout] = useState<InvestmentPayoutArray[]>();
  const [payloadId, setPayloadId] = useState('');

  const fetchInvestments = async (page: number) => {
    try {
      setLoading(true);
      const res = await getInvestments(page);
      if (res) {
        setInvestmentsList(res);
        setLoading(false);
        res.current_page < res.total_pages && setCurrentPage(res.current_page + 1);
      }
    } catch (err: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching investments',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchInvestments(currentPage);
  }, []);

  const onEditClick = (investment: any) => {
    setInvestmentToEdit(investment);
    openManageModal();
  };

  const onDeleteClick = async (investment: InvestmentResultsArray) => {
    try {
      setLoading(true);
      const res = await deleteInvestmentById(investment?.id);
      if (res) {
        toast({
          title: 'Investment deleted successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
      }
      fetchInvestments(currentPage);
    } catch (err: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while deleting investment',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      onClose();
      console.log('Error While Fetch All Users ', err);
    }
  };

  const handlePrevPage = () => {
    investmentsList?.current_page && fetchInvestments(currentPage - 1);
  };

  const handleNextPage = () => {
    investmentsList?.current_page && fetchInvestments(currentPage + 1);
  };

  useEffect(() => {
    investmentsList?.results[0] && setInvestmentId(investmentsList?.results[0]?.id);
  }, [investmentsList]);

  const fetchInvestmentById = async (id: number) => {
    try {
      setPayoutsLoading(true);
      const res = await getPayoutsByInvestmentId(id);
      if (res) {
        setInvestmentPayout(res?.payouts);
        setPayoutsLoading(false);
        // res.current_page < res.total_pages && setCurrentPage(res.current_page + 1);
      }
    } catch (err: any) {
      setPayoutsLoading(false);
      toast({
        title: 'Something went wrong while fetching investments',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (investmentId) {
      fetchInvestmentById(investmentId);
    }
  }, [investmentId]);

  return (
    <>
      <ClaimTokenModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setPayloadId('');
        }}
        payoutId={payloadId}
      />
      <ManageInvestmentModal
        isOpen={manageModalOpen}
        onClose={() => {
          closeManageModal();
        }}
        onAdded={() => {
          closeManageModal();
          fetchInvestments(currentPage);
        }}
        investmentToEdit={investmentToEdit}
      />
      <DeleteInvestmentModal
        isOpen={deleteModalOpen}
        onClose={() => {
          closeDeleteModal();
        }}
        clientToDelete={investmentToDelete}
        onDeleteClick={() => {
          closeDeleteModal();
          investmentToDelete && onDeleteClick(investmentToDelete);
        }}
      />
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <Box width="100%" height="100%">
          <Box pb="30px" display="flex" alignItems="center">
            <Box display="flex" alignItems="center" justifyContent="center" gap={3}>
              <Text
                fontFamily="Montserrat"
                fontWeight="700"
                fontSize={['16px', '18px', '22px', '36px', '36px']}
                color="#1A1A1A"
                marginLeft={['20px', '20px', 'unset']}
              >
                Keep track of your investments
              </Text>
              <InvestmentLogo />
            </Box>
          </Box>

          <Box width="100%" gap="20px" display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']}>
            <Box width={['100%', '100%', '100%', '100%', '60%']}>
              <Box bg="white" borderRadius="20px" border="1px solid #E2E2E2" padding="16px">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Text fontFamily="Montserrat" fontSize={['14px', '18px', '24px']} fontWeight="600" color="#1A1A1A">
                    Investment Tracker
                  </Text>
                  <Button
                    leftIcon={<AddIcon color="white" />}
                    color="white"
                    borderRadius="12px"
                    background="#0FBF95"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="normal"
                    onClick={() => {
                      setInvestmentToEdit(null);
                      openManageModal();
                    }}
                    _hover={{
                      color: 'white',
                      bg: '#0FBF75',
                    }}
                  >
                    Add Investment
                  </Button>
                </Box>

                <TableContainer marginTop="10px">
                  <Table variant="simple">
                    <Thead>
                      <Tr borderRadius="10px" background="#E2E2E2" marginBottom="10px">
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                          borderRadius="10px 0px 0px 10px"
                        >
                          Client
                        </Th>
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                        >
                          Amount Claimed
                        </Th>
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                        >
                          Invested
                        </Th>
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                          borderRadius="0px 10px 10px 0px"
                          marginBottom="10px"
                        >
                          Actions
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {investmentsList?.results &&
                        investmentsList?.results?.length > 0 &&
                        investmentsList?.results.map((investment: any) => (
                          <Tr
                            key={investment.id}
                            cursor="pointer"
                            borderBottom={investment.id === investmentId ? ' 1px solid #E2E2E2' : 'unset'}
                            backgroundColor={investment.id === investmentId ? '#F6F6F6' : 'unset'}
                          >
                            <Td
                              fontFamily="Montserrat"
                              fontSize="13px"
                              fontWeight="500"
                              borderRadius="10px 0px 0px 10px"
                            >
                              {investment.client?.name}
                            </Td>
                            <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                              {investment.total_amount}
                            </Td>
                            <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                              ${investment?.total_amount}
                            </Td>
                            <Td
                              display="flex"
                              gap="10px"
                              alignItems="center"
                              backgroundColor={investment.id === investmentId ? '#F6F6F6' : 'unset'}
                              borderRadius="0px 10px 10px 0px"
                            >
                              <Button
                                onClick={() => onEditClick(investment)}
                                variant="outline"
                                borderRadius="12px"
                                fontSize="13px"
                                fontFamily="Montserrat"
                                fontWeight="500"
                                border=" 1px solid var(--Color-Neutral-500, #8C8C8C)"
                                background=" var(--Color-Neutral-50, #FFF)"
                                width="27px"
                                height="28px"
                                padding="4px 8px"
                                color="#1A1A1A"
                                cursor="pointer"
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => setInvestmentId(investment.id)}
                                variant="outline"
                                borderRadius="12px"
                                fontSize="13px"
                                fontFamily="Montserrat"
                                fontWeight="500"
                                border=" 1px solid var(--Color-Neutral-500, #8C8C8C)"
                                background=" var(--Color-Neutral-50, #FFF)"
                                padding="4px 8px"
                                height="28px"
                                color="#1A1A1A"
                                cursor="pointer"
                              >
                                View Payouts
                              </Button>
                              <Delete
                                boxSize={6}
                                cursor="pointer"
                                onClick={() => {
                                  setInvestmentToDelete(investment);
                                  openDeleteModal();
                                }}
                              />
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="20px" padding="0px 20px">
                  <Button
                    fontFamily="Montserrat"
                    fontSize="14px"
                    variant="link"
                    leftIcon={<ArrowLeftIcon />}
                    onClick={handlePrevPage}
                    isDisabled={investmentsList?.current_page === 1}
                  >
                    Previous
                  </Button>
                  <Text
                    fontFamily="Montserrat"
                    fontSize={['10px', '10px', '14px']}
                  >{`Page ${investmentsList?.current_page} of ${totalPages}`}</Text>
                  <Button
                    fontFamily="Montserrat"
                    fontSize="14px"
                    variant="link"
                    rightIcon={<ArrowRightIcon />}
                    onClick={handleNextPage}
                    isDisabled={investmentsList?.current_page === totalPages}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box width={['100%', '100%', '100%', '100%', '40%']}>
              <Box
                bg="white"
                borderRadius="20px"
                border="1px solid #E2E2E2"
                padding="16px"
                maxHeight="500px"
                overflow="scroll"
              >
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Text fontFamily="Montserrat" fontSize="24px" fontWeight="600" color="#1A1A1A">
                    Payouts
                  </Text>
                  <Text fontFamily="Montserrat" fontSize="14px" fontWeight="600" color="#0FBF95" cursor="pointer">
                    Edit Investment
                  </Text>
                </Box>

                {payoutsLoading ? (
                  <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Spinner />
                  </Box>
                ) : (
                  <TableContainer marginTop="10px">
                    <Table variant="simple" display={['table', 'table', 'table']}>
                      <Thead>
                        <Tr borderRadius="10px" background="#F2F2F2">
                          <Th
                            fontFamily="Montserrat"
                            fontSize="13px"
                            fontWeight="700"
                            color="#1A1A1A"
                            textTransform="capitalize"
                            borderRadius="10px 0px 0px 10px"
                          >
                            Next payout
                          </Th>
                          <Th
                            fontFamily="Montserrat"
                            fontSize="13px"
                            fontWeight="700"
                            color="#1A1A1A"
                            textTransform="capitalize"
                          >
                            Claim Amount
                          </Th>
                          <Th
                            fontFamily="Montserrat"
                            fontSize="13px"
                            fontWeight="700"
                            color="#1A1A1A"
                            textTransform="capitalize"
                            borderRadius="0px 10px 10px 0px"
                          >
                            Payout part
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {investmentPayout &&
                          investmentPayout.length > 0 &&
                          investmentPayout.map((payout) => (
                            <Tr key={payout?.id}>
                              <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                                <Countdown
                                  endDateString={payout.date}
                                  paid={payout.paid}
                                  openClaimModal={() => {
                                    setPayloadId(payout?.id.toString());
                                    onOpen();
                                  }}
                                />
                              </Td>
                              <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                                {payout.amount}
                              </Td>
                              <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                                {payout.payout_part} of {payout.total_payouts}
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
