import {
  useToast,
  FormControl,
  FormLabel,
  Button,
  Box,
  Spinner,
  VStack,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Input,
  Flex,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InvestmentResultsArray, ManageInvestmentModalProps } from '../../interfaces/components';
import { getClients } from '../../api/InvestorApis/client';
import { CloseIcon } from '@chakra-ui/icons';
import { AppStoreState, useAppStore } from '../../store';
import { patchInvestment, postInvestment } from '../../api/InvestorApis/investments';
import { formatDateForInput } from '../../utils/commonFunctions';

export default function ManageModal({ isOpen, onClose, investmentToEdit, onAdded }: ManageInvestmentModalProps) {
  const setClientsList = useAppStore((state: AppStoreState) => state.setClientsList);
  const clientsList = useAppStore((state: AppStoreState) => state.clientsList);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InvestmentResultsArray>({
    defaultValues: {
      client: investmentToEdit ? investmentToEdit?.client?.id : '',
      token_address: investmentToEdit ? investmentToEdit?.token_address : '',
      tge_percentage: investmentToEdit ? investmentToEdit?.tge_percentage : '',
      tge: investmentToEdit ? formatDateForInput(investmentToEdit?.tge) : '',
      cliff: investmentToEdit ? investmentToEdit?.cliff : '',
      vesting: investmentToEdit ? investmentToEdit?.vesting : '',
      total_tokens: investmentToEdit ? investmentToEdit?.total_tokens : '',
      total_amount: investmentToEdit ? investmentToEdit?.total_amount : '',
    },
  });

  useEffect(() => {
    reset({
      client: investmentToEdit ? investmentToEdit?.client?.id : '',
      token_address: investmentToEdit ? investmentToEdit?.token_address : '',
      tge_percentage: investmentToEdit ? investmentToEdit?.tge_percentage : '',
      tge: investmentToEdit ? formatDateForInput(investmentToEdit?.tge) : '',
      cliff: investmentToEdit ? investmentToEdit?.cliff : '',
      vesting: investmentToEdit ? investmentToEdit?.vesting : '',
      total_tokens: investmentToEdit ? investmentToEdit?.total_tokens : '',
      total_amount: investmentToEdit ? investmentToEdit?.total_amount : '',
    });
  }, [investmentToEdit]);

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (data: InvestmentResultsArray) => {
    setLoading(true);
    if (investmentToEdit) {
      try {
        const res = await patchInvestment(investmentToEdit?.id, data);
        if (res) {
          setLoading(false);
          toast({
            title: 'Client has been updated successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAdded();
          reset();
        }
      } catch (error: any) {
        setLoading(false);
        toast({
          title: 'Something went wrong while editing client.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      try {
        const { tge_percentage, tge, cliff, ...newData } = data as any;

        if (tge_percentage !== '') {
          newData.tge_percentage = data.tge_percentage;
        }
        if (tge !== '') {
          newData.tge = data.tge;
        }
        if (cliff !== '') {
          newData.cliff = data.cliff;
        }
        const res = await postInvestment(newData);
        if (res) {
          setLoading(false);
          toast({
            title: 'Investment has been added successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAdded();
          reset();
        }
      } catch (error: any) {
        setLoading(false);
        toast({
          title: 'Something went wrong while adding investment.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const fetchClients = async (page: number) => {
    try {
      setLoading(true);
      const res = await getClients(page);
      if (res) {
        setClientsList(res);

        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      console.log('Error While Fetch All Users ', err);
    }
  };

  useEffect(() => {
    fetchClients(1);
  }, []);

  const loadMore = () => {
    console.log('load moreee');
    // if (currentPage < clientsList.totalPages && !loading) {
    //   const nextPage = currentPage + 1;
    //   setCurrentPage(nextPage);
    //   fetchClients(nextPage);
    // }
  };

  // const handleInputChange = (event: any) => {
  //   const { value } = event.target;
  //   const numericValue = parseFloat(value);
  //   if (numericValue > 100) {
  //     setValue('tge_percentage', 100 as any);
  //   } else if (numericValue < 1) {
  //     setValue('tge_percentage', 1 as any);
  //   }
  // };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        width={['90vw', '80vw', '546px', '546px', '546px']}
        borderRadius="20px"
        padding={['24px', '36px', '36px']}
        height={['auto', 'auto', 'unset']}
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
        maxHeight={['500px', '500px', 'unset']}
        overflowY="scroll"
      >
        <ModalBody padding="0px" position="relative">
          <CloseIcon top="0" right="0" position="absolute" boxSize={4} onClick={onClose} cursor="pointer" />

          {loading ? (
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
              <Spinner />
            </Box>
          ) : (
            <Box>
              <Box>
                <Text color="#1A1A1A" fontFamily="Montserrat" fontSize="20px" fontWeight="700" lineHeight="normal">
                  {investmentToEdit ? 'Edit Investment' : ' Add Investment'}
                </Text>
              </Box>

              <form onSubmit={handleSubmit(handleFormSubmit)} style={{ marginTop: '24px' }}>
                <VStack spacing={1} align="stretch">
                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px" marginTop="12px">
                    <FormControl>
                      <FormLabel htmlFor="client" color="#8C8C8C" fontSize="12px" fontWeight={500}>
                        Select Client
                      </FormLabel>
                      <Select
                        id="client"
                        // placeholder="Select client"
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                        {...register('client', {
                          required: 'Client is required',
                        })}
                        onScroll={(e) => {
                          const target = e.target as HTMLSelectElement;
                          if (target.scrollHeight - target.scrollTop === target.clientHeight) {
                            loadMore();
                          }
                        }}
                        border="1px solid red"
                      >
                        {clientsList?.results &&
                          clientsList?.results.length > 0 &&
                          clientsList?.results.map((option) => (
                            <option key={option.id} value={option.id} style={{ border: '1px solid red' }}>
                              {option.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="token_address" color="#8C8C8C" fontSize="12px" fontWeight={500}>
                        Token Address
                      </FormLabel>
                      <Input
                        id="token_address"
                        type="text"
                        {...register('token_address', {
                          required: 'Wallet address is required',
                          pattern: {
                            value: /^0x[a-fA-F0-9]{40}$/,
                            message: 'Invalid Ethereum address',
                          },
                        })}
                        placeholder="Enter a token address"
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                    </FormControl>
                  </Flex>
                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px" marginTop={'12px !important'}>
                    <FormControl>
                      <FormLabel htmlFor="total_tokens" color="#8C8C8C" fontSize="12px" fontWeight={500}>
                        Total token amount
                      </FormLabel>
                      <Input
                        id="total_tokens"
                        type="number"
                        placeholder="Amount"
                        {...register('total_tokens', {
                          required: 'First name is required',
                        })}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                      {/* <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage> */}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="total_amount" color="#8C8C8C" fontSize="12px" fontWeight={500}>
                        Total invested (in USD)
                      </FormLabel>
                      <Input
                        id="total_amount"
                        type="number"
                        {...register('total_amount', {
                          required: 'First name is required',
                        })}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                        placeholder="Amount"
                      />
                      {/* <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage> */}
                    </FormControl>
                  </Flex>
                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px" marginTop={'12px !important'}>
                    <FormControl isInvalid={!!errors.tge_percentage}>
                      <FormLabel htmlFor="tge_percentage" color="#8C8C8C" fontSize="12px" fontWeight={500}>
                        TGE percentage(optional)
                      </FormLabel>
                      <Input
                        id="tge_percentage"
                        type="number"
                        min={1}
                        max={100}
                        step={1}
                        placeholder="0%"
                        {...register('tge_percentage', {})}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="tge" color="#8C8C8C" fontSize="12px" fontWeight={500}>
                        Tge Date(Optional)
                      </FormLabel>
                      <Input
                        id="tge"
                        width="221px"
                        type="datetime-local"
                        {...register('tge', {})}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                        placeholder="DD-MM-YY"
                      />
                    </FormControl>
                  </Flex>
                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px" marginTop={'12px !important'}>
                    <FormControl>
                      <FormLabel htmlFor="cliff" color="#8C8C8C" fontSize="12px" fontWeight={500}>
                        Cliff (optional)
                      </FormLabel>
                      <Input
                        id="cliff"
                        type="number"
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                        placeholder="0"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel htmlFor="vesting" color="#8C8C8C" fontSize="12px" fontWeight={500}>
                        Vesting
                      </FormLabel>
                      <Input
                        id="vesting"
                        type="number"
                        {...register('vesting', {
                          required: 'Vesting required',
                        })}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                        placeholder="0"
                      />
                      {/* <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage> */}
                    </FormControl>
                  </Flex>

                  <Box
                    display="flex"
                    gap={['10px', '10px', '14px']}
                    flexDirection={['column-reverse', 'column-reverse', 'row']}
                  >
                    <Button
                      marginTop={['unset', 'unset', '24px']}
                      borderRadius="14px"
                      border="1px solid #8C8C8C"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      onClick={onClose}
                      width={['auto', 'auto', '50%']}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="white"
                      whiteSpace="break-spaces"
                      type="submit"
                      marginTop="24px"
                      width={['auto', 'auto', '50%']}
                      borderRadius="14px"
                      background="#0FBF95"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      _hover={{
                        color: 'white',
                        bg: '#0FBF75',
                      }}
                    >
                      {investmentToEdit ? 'Edit Investment' : 'Add Investment'}
                    </Button>
                  </Box>
                </VStack>
              </form>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
