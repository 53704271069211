import {
  Box,
  Button,
  Text,
  VStack,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Input,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { useAppStore, AppStoreState } from '../../store';
import { useForm } from 'react-hook-form';
import { getClients } from '../../api/InvestorApis/client';
import { postLiquidation } from '../../api/InvestorApis/liquidity';
import { useNavigate } from 'react-router-dom';

type Liquidate = {
  client: string;
  token_amount: string;
  claim_value: number;
  sell_value: number;
};

export default function LiquidateForm() {
  const toast = useToast();
  const navigate = useNavigate();
  const setClientsList = useAppStore(
    (state: AppStoreState) => state.setClientsList,
  );
  const clientsList = useAppStore((state: AppStoreState) => state.clientsList);
  const { handleSubmit, register } = useForm<Liquidate>({
    defaultValues: {
      client: '',
      token_amount: '',
      claim_value: 0,
      sell_value: 0,
    },
  });

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (data: Liquidate) => {
    setLoading(true);
    toast.promise(postLiquidation(data), {
      success: { title: 'Form submitted', description: 'Values are store' },
      error: {
        title: 'Error',
      },
      loading: { title: 'Processing' },
    });

    setLoading(false);
    console.log('loading = ', loading);
  };

  const fetchClients = async (page: number) => {
    try {
      setLoading(true);
      const res = await getClients(page);
      if (res) {
        setClientsList(res);
        setLoading(false);
        console.log(res, 'res');
      }
    } catch (err: any) {
      setLoading(false);
      console.log('Error While Fetch All Users ', err);
    }
  };

  useEffect(() => {
    fetchClients(1);
  }, []);

  const loadMore = () => {
    console.log('load moreee');
    // if (currentPage < clientsList.totalPages && !loading) {
    //   const nextPage = currentPage + 1;
    //   setCurrentPage(nextPage);
    //   fetchClients(nextPage);
    // }
  };

  return (
    <Box>
      <Box>
        <Text
          color="#1A1A1A"
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="700"
          lineHeight="normal"
        >
          Track your liquidation
        </Text>
      </Box>

      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        style={{ marginTop: '24px' }}
      >
        <VStack spacing={1} align="stretch">
          <Flex direction="column" gap="12px" marginTop="12px">
            <FormControl>
              <FormLabel
                htmlFor="client"
                color="#8C8C8C"
                fontSize="12px"
                fontWeight={500}
              >
                Select Client
              </FormLabel>
              <Select
                id="client"
                // placeholder="Select client"
                backgroundColor="#F2F2F2"
                borderRadius="12px"
                {...register('client')}
                height="44px"
                onScroll={(e) => {
                  const target = e.target as HTMLSelectElement;
                  if (
                    target.scrollHeight - target.scrollTop ===
                    target.clientHeight
                  ) {
                    loadMore();
                  }
                }}
                border="1px solid red"
              >
                {clientsList?.results &&
                  clientsList?.results.length > 0 &&
                  clientsList?.results.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      style={{ border: '1px solid red' }}
                    >
                      {option.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel
                htmlFor="token_address"
                color="#8C8C8C"
                fontSize="12px"
                fontWeight={500}
              >
                Amount of tokens sold
              </FormLabel>
              <Input
                id="token_amount"
                type="number"
                {...register('token_amount')}
                placeholder="Amount"
                backgroundColor="#F2F2F2"
                borderRadius="12px"
                height="44px"
              />
            </FormControl>
            <FormControl>
              <FormLabel
                htmlFor="token_address"
                color="#8C8C8C"
                fontSize="12px"
                fontWeight={500}
              >
                Claim value (in USD)
              </FormLabel>
              <Input
                id="claim_value"
                {...register('claim_value')}
                type="number"
                placeholder="Amount"
                backgroundColor="#F2F2F2"
                borderRadius="12px"
                height="44px"
              />
            </FormControl>
            <FormControl>
              <FormLabel
                htmlFor="token_address"
                color="#8C8C8C"
                fontSize="12px"
                fontWeight={500}
              >
                Sale value (in USD)
              </FormLabel>
              <Input
                id="sell_value"
                {...register('sell_value')}
                type="number"
                placeholder="Amount"
                backgroundColor="#F2F2F2"
                borderRadius="12px"
                height="44px"
              />
            </FormControl>
          </Flex>

          <Box
            display="flex"
            gap={['10px', '10px', '14px']}
            flexDirection={['column-reverse', 'column-reverse', 'row']}
          >
            <Button
              marginTop={['unset', 'unset', '24px']}
              borderRadius="14px"
              border="1px solid #8C8C8C"
              fontWeight={600}
              fontFamily="Montserrat"
              fontSize="14px"
              onClick={() => navigate('/')}
              width={['auto', 'auto', '50%']}
            >
              Cancel
            </Button>
            <Button
              color="white"
              whiteSpace="break-spaces"
              type="submit"
              marginTop="24px"
              width={['auto', 'auto', '50%']}
              borderRadius="14px"
              background="#0FBF95"
              fontWeight={600}
              fontFamily="Montserrat"
              fontSize="14px"
              _hover={{
                color: 'white',
                bg: '#0FBF75',
              }}
            >
              Save
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
}
