import {
  useToast,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Spinner,
  VStack,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useAppStore, AppStoreState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { ClaimTokenModalProps, ClaimTokenPayload } from '../../interfaces/components';
import { claimToken } from '../../api/InvestorApis/apis';

export default function ClaimTokenModal({ isOpen, onClose, payoutId }: ClaimTokenModalProps) {
  const { register, handleSubmit, reset } = useForm<ClaimTokenPayload>();

  const navigate = useNavigate();
  const toast = useToast();
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);

  const handleFormSubmit = async (data: ClaimTokenPayload) => {
    {
      try {
        setLoading(true);
        const res = payoutId && (await claimToken(payoutId, { dollar_value: Number(data.amount) }));
        if (res) {
          setLoading(false);
          onClose();
          toast({
            title: 'Claim has been added successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          reset();
        }
      } catch (error: any) {
        setLoading(false);
        toast({
          title: 'Something went wrong while adding claim.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['90vw', '380px', '404px', '404px', '404px']}
        borderRadius="20px"
        padding="36px"
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <ModalCloseButton />
        <ModalBody padding="0px">
          {loading ? (
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
              <Spinner />
            </Box>
          ) : (
            <Box>
              <Box>
                <Text
                  color="#1A1A1A"
                  fontFamily="Montserrat"
                  fontSize={['16px', '28px', '20px']}
                  fontStyle=" normal"
                  fontWeight=" 700"
                  lineHeight=" normal"
                >
                  Claim Token
                </Text>
              </Box>

              <form onSubmit={handleSubmit(handleFormSubmit)} style={{ marginTop: '24px' }}>
                <VStack spacing={1} align="stretch">
                  <FormControl>
                    <FormLabel htmlFor="amount" color="#8C8C8C" fontSize="12px" fontFamily="Montserrat">
                      Token in dollars
                    </FormLabel>
                    <Input
                      borderRadius="12px"
                      variant="filled"
                      placeholder="Enter dollar amount"
                      id="amount"
                      type="number"
                      {...register('amount', {
                        required: 'Add token amount',
                      })}
                    />
                    {/* <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage> */}
                  </FormControl>

                  <Box display="flex" gap="14px">
                    <Button
                      marginTop="24px"
                      width="161px"
                      borderRadius="14px"
                      border=" 1px solid #8C8C8C"
                      background="#FFF"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      onClick={() => navigate('/payouts')}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="white"
                      whiteSpace="break-spaces"
                      type="submit"
                      marginTop="24px"
                      width="161px"
                      borderRadius="14px"
                      background="#0FBF95"
                      fontWeight={600}
                      fontSize="14px"
                      fontFamily="Montserrat"
                      _hover={{
                        color: 'white',
                        bg: '#0FBF75',
                      }}
                    >
                      Claim
                    </Button>
                  </Box>
                </VStack>
              </form>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
