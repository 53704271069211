import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  Text,
  DrawerOverlay,
  Button,
  DrawerHeader,
} from '@chakra-ui/react';
import React from 'react';
import { DrawerProps, NavbarOption } from '../../interfaces/components';
import { useNavigate } from 'react-router-dom';
import LogoutLogoDrawer from '../../Icons/LogoutLogoDrawer';
import { useAppStore, AppStoreState } from '../../store';

function Drawer({ isOpen, onClose, navbarOptions, activeView }: DrawerProps) {
  const navigate = useNavigate();
  const setLoginApiResponse = useAppStore((state: AppStoreState) => state.setLoginApiResponse);
  return (
    <ChakraDrawer isOpen={isOpen} placement="left" onClose={onClose} autoFocus={false}>
      <DrawerOverlay />
      <DrawerContent borderRadius="14px" margin="10px" padding="24px 12px 12px 12px">
        <DrawerHeader
          borderBottom="1px solid var(--Color-Neutral-100, #F2F2F2)"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap="8px"
          padding="16px 6px"
        >
          <Box
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="600"
            bg="#0FBF95"
            color="white"
            padding="10px"
            borderRadius="100%"
            width="30px"
            height="30px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            U
          </Box>
          <Text fontFamily="Montserrat" color="#1A1A1A" fontWeight="600" fontSize="14px" userSelect="none">
            Username
          </Text>
        </DrawerHeader>

        <DrawerBody padding="0px">
          <Box
            paddingTop="12px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="4px"
            width="100%"
          >
            {navbarOptions.map((navbarOption: NavbarOption) => (
              <Box
                key={navbarOption.key}
                width="100%"
                fontFamily="Montserrat"
                fontWeight="600"
                fontSize="14px"
                padding="8px"
                borderRadius="8px"
                color={activeView === navbarOption.key ? '#0FBF95' : '#1A1A1A'}
                bg={activeView === navbarOption.key ? '#F6F6F6' : 'white'}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                _hover={{
                  color: '#0FBF95',
                  bg: '#F6F6F6',
                }}
                transition="0.2s all"
                cursor="pointer"
                onClick={() => {
                  onClose();
                  navigate(navbarOption.href);
                }}
              >
                {navbarOption.text}
              </Box>
            ))}
          </Box>
        </DrawerBody>

        <DrawerFooter
          borderTop="1px solid var(--Color-Neutral-100, #F2F2F2)"
          flexDirection="column"
          alignItems="flex-start"
          padding="0px"
          gap="10px"
        >
          <Button
            variant="link"
            color="black"
            fontSize="14px"
            fontFamily="Montserrat"
            fontWeight="500"
            w="100%"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={1}
            padding="5px"
            cursor="pointer"
            onClick={() => {
              setLoginApiResponse(null);
              navigate('/login');
            }}
          >
            <LogoutLogoDrawer boxSize={6} />
            <Text>Logout</Text>
          </Button>
          <Box>
            <Text color="#8C8C8C" fontSize="12px" fontFamily="Montserrat" fontWeight="500">
              Covey © 2024.
            </Text>
            <Text color="#8C8C8C" fontSize="12px" fontFamily="Montserrat" fontWeight="500">
              All rights reserved.
            </Text>
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  );
}

export default Drawer;
