import {
  useToast,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Spinner,
  VStack,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ManageClientModalProps } from '../../interfaces/components';
import { patchClient, postClient } from '../../api/InvestorApis/client';
import { ClientPayload } from '../../interfaces/components';
import { CloseIcon } from '@chakra-ui/icons';

export default function ManageClientModal({ isOpen, onClose, clientToEdit, onAdded }: ManageClientModalProps) {
  const { register, handleSubmit, reset } = useForm<ClientPayload>({
    defaultValues: {
      name: clientToEdit ? clientToEdit?.name : '',
    },
  });

  useEffect(() => {
    reset({
      name: clientToEdit?.name || '',
    });
  }, [clientToEdit]);

  const clientExists = clientToEdit?.name ? clientToEdit?.name?.length > 0 : false;

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (data: ClientPayload) => {
    setLoading(true);

    if (clientExists) {
      try {
        const res = await patchClient(clientToEdit?.id || 0, data);
        if (res) {
          setLoading(false);
          toast({
            title: 'Client has been updated successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAdded();
          reset();
        }
      } catch (error: any) {
        setLoading(false);
        toast({
          title: 'Something went wrong while editing client.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      try {
        const res = await postClient(data);
        if (res) {
          setLoading(false);
          toast({
            title: 'Client has been added successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAdded();
          reset();
        }
      } catch (error: any) {
        setLoading(false);
        toast({
          title: 'Something went wrong while adding client.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['90vw', '380px', '404px', '404px', '404px']}
        borderRadius="20px"
        padding={['24px', '36px', '36px']}
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <ModalBody padding="0px" position="relative">
          <CloseIcon top="0" right="0" position="absolute" boxSize={4} onClick={onClose} cursor="pointer" />

          {loading ? (
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
              <Spinner />
            </Box>
          ) : (
            <Box>
              <Box>
                <Text color="#1A1A1A" fontFamily="Montserrat" fontSize="20px" fontWeight="700" lineHeight="normal">
                  {clientExists ? 'Edit Client' : ' Add Client'}
                </Text>
              </Box>

              <form onSubmit={handleSubmit(handleFormSubmit)} style={{ marginTop: '24px' }}>
                <VStack spacing={1} align="stretch">
                  <FormControl>
                    <FormLabel htmlFor="name" color="#8C8C8C" fontSize="12px" fontFamily="Montserrat">
                      Client Name
                    </FormLabel>
                    <Input
                      borderRadius="12px"
                      variant="filled"
                      placeholder="Enter client name"
                      id="name"
                      {...register('name', {
                        required: 'Client name is required',
                      })}
                    />
                    {/* <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage> */}
                  </FormControl>

                  <Box display="flex" gap="14px">
                    <Button
                      marginTop="24px"
                      width="161px"
                      borderRadius="14px"
                      border="1px solid #8C8C8C"
                      background="#FFF"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="white"
                      whiteSpace="break-spaces"
                      type="submit"
                      marginTop="24px"
                      width="161px"
                      borderRadius="14px"
                      background="#0FBF95"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      _hover={{
                        color: 'white',
                        bg: '#0FBF75',
                      }}
                    >
                      {clientExists ? 'Edit Client' : 'Create Client'}
                    </Button>
                  </Box>
                </VStack>
              </form>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
