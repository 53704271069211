import {
  useToast,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Spinner,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { UserLogInResponse, UserLoginPayload } from '../../interfaces/components';
import { userLogin } from '../../api/InvestorApis/apis';
import { AppStoreState, useAppStore } from '../../store';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      wallet_address: '',
      email: '',
      password: '',
      first_name: '',
      last_name: '',
    },
  });
  console.log('erress', errors);
  const navigate = useNavigate();
  const loginApiResponse = useAppStore((state: AppStoreState) => state.loginApiResponse);
  const setLoginApiResponse = useAppStore((state: AppStoreState) => state.setLoginApiResponse);
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);

  const toast = useToast();
  const handleFormSubmit = async (data: UserLoginPayload) => {
    try {
      setLoading(true);
      const res = await userLogin(data);
      if (res) {
        setLoginApiResponse(res as unknown as UserLogInResponse);
        setLoading(false);
        toast({
          title: 'Login successful!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/');
        reset();
      }
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while while logging in.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.log('Error While Fetch All Users ', error);
    }
  };

  useEffect(() => {
    loginApiResponse?.data?.access_token && localStorage.setItem('access_token', loginApiResponse?.data?.access_token);
    loginApiResponse?.data?.refresh_token &&
      localStorage.setItem('refresh_token', loginApiResponse?.data?.refresh_token);
  }, [loginApiResponse, loading]);

  return (
    <>
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <Box
          boxShadow={['none', 'none', '0px 0px 10px 0px rgba(0, 0, 0, 0.15)']}
          padding={['20px 20px', '40px 40px', '40px 40px']}
          borderRadius="12px"
          border="1px solid #E2E2E2"
          width={['90vw', '90vw', '500px']}
        >
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <VStack spacing={4}>
              <Text fontSize="24px" fontFamily="Montserrat" fontWeight="700">
                Log in
              </Text>
              {/* Email */}
              <FormControl isRequired>
                <FormLabel htmlFor="email" color="#8C8C8C" fontSize="12px" fontFamily="Montserrat">
                  Username
                </FormLabel>
                <Input
                  borderRadius="12px"
                  variant="filled"
                  id="email"
                  
                  placeholder="Enter your username"
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Invalid email address',
                    },
                  })}
                />
                <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
              </FormControl>

              {/* Password */}
              <FormControl isRequired>
                <FormLabel htmlFor="password" color="#8C8C8C" fontSize="12px" fontFamily="Montserrat">
                  Password
                </FormLabel>
                <Input
                  borderRadius="12px"
                  variant="filled"
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  {...register('password', {
                    required: 'Password is required',
                    minLength: {
                      value: 6,
                      message: 'Password must be at least 6 characters',
                    },
                  })}
                />
                <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
              </FormControl>

              <Text variant="link" color="#8C8C8C" fontSize="12px" fontFamily="Montserrat">
                Not a member?&nbsp;
                <span
                  style={{ color: '#0FBF95', fontWeight: '700', cursor: 'pointer' }}
                  onClick={() => navigate('/signup')}
                >
                  Signup
                </span>
              </Text>

              <Button
                bg="#0FBF95"
                color="white"
                type="submit"
                width="full"
                fontFamily="Montserrat"
                borderRadius="14px"
                fontSize="14px"
              >
                Login
              </Button>

              <Button variant="link" color="#0FBF95" fontSize="12px" fontFamily="Montserrat">
                Forgot password?
              </Button>
            </VStack>
          </form>
        </Box>
      )}
    </>
  );
}
