import { InvestmentPayload } from '../../interfaces/components';
import axios from '../axios';

export const getInvestments = async (page: number) => {
  try {
    const endPoint = `api/investments/?page=${page}`;
    const res = await axios.get<InvestmentPayload>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const getPayoutsByInvestmentId = async (id: number) => {
  try {
    const endPoint = `api/investments/${id}`;
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const postInvestment = async (data: any) => {
  try {
    const endPoint = `api/investments/`;
    const res = await axios.post<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const patchInvestment = async (id: number | string, data: any) => {
  try {
    const endPoint = `api/investments/${id}/`;
    const res = await axios.patch<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const deleteInvestmentById = async (id: number) => {
  try {
    const endPoint = `api/investments/${id}`;
    const res = await axios.delete<any>(endPoint);
    if (!res) throw 'Something went wrong';
    return res.status;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
