import axiosClient, { AxiosInstance } from 'axios';

const TIMEOUT = 35000; //15sec
const SERVER_URL = process.env.REACT_APP_API_HOST || 'https://api.tracker.baboons.tech/';

const axios: AxiosInstance = axiosClient.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  //   params: clean({
  //     t: new Date().getTime(),
  //     sub_id: getCookie('sub_id'),
  //   }),

  timeout: TIMEOUT,
  // transformResponse: (res) => transformKeys.toCamelCase(JSON.parse(res)),
});

const refreshToken = async () => {
  try {
    const loginDetails = localStorage.getItem('loginApiResponse');
    const parsedLoginDetails = loginDetails && JSON.parse(loginDetails);
    const refreshTokenString = parsedLoginDetails && parsedLoginDetails?.data?.refresh_token;

    if (refreshTokenString) {
      const payload = { refresh: refreshTokenString };
      const endPoint = 'api/token/refresh/';
      const res = await axios.post(endPoint, payload);
      return res.data;
    }
  } catch (e) {
    console.log('Error', e);
  }
};

axios.interceptors.request.use(
  async (config) => {
    const loginDetails = localStorage.getItem('loginApiResponse');
    const parsedLoginDetails = loginDetails && JSON.parse(loginDetails);
    const accessToken =
      parsedLoginDetails && parsedLoginDetails?.data
        ? parsedLoginDetails?.data?.access_token
        : parsedLoginDetails?.access_token;
    if (accessToken && config?.headers) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    config.headers['x-api-key'] = process.env.REACT_APP_TITLE_X_KEY || '9KVvI9QM_98vtE__EYrhCgxFad-6do8fRB9050923uc';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (originalRequest.url.includes('refresh') && error.response.status === 401) {
      // message.error('Session expired, please login again!');
      // store.dispatch(logout());
      localStorage.removeItem('loginApiResponse');
    } else if ((error?.response?.status === 403 || error?.response?.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true;
      const res = await refreshToken();
      console.log('res', res);
      // store.dispatch(setToken(res.access));
      const updatedObject = { ...res, access_token: res.access };
      delete updatedObject.access;
      localStorage.setItem('loginApiResponse', JSON.stringify(updatedObject));
      axios.defaults.headers.common['Authorization'] = `Bearer ${res.access}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default axios;
