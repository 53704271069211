import { ClientPayload, ClientResponse } from '../../interfaces/components';
import axios from '../axios';

export const postClient = async (data: any) => {
  try {
    const endPoint = `api/clients/`;
    const res = await axios.post<ClientResponse>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const patchClient = async (id: number | string, data: ClientPayload) => {
  try {
    const endPoint = `api/clients/${id}/`;
    const res = await axios.patch<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const getClients = async (page: number) => {
  try {
    const endPoint = `api/clients/?page=${page}`;
    const res = await axios.get<ClientResponse>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const getClientbyId = async (id: number) => {
  try {
    const endPoint = `api/clients/${id}/`;
    const res = await axios.get<Array<any>>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const deleteClientById = async (id: number) => {
  try {
    const endPoint = `api/clients/${id}`;
    const res = await axios.delete<any>(endPoint);
    if (!res) throw 'Something went wrong';
    return res.status;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
