import CoveyLogo from '../../assets/logo.png';
import { Box, Image } from '@chakra-ui/react';
import React from 'react';

export interface UnauthorizedOverlayProps {
  children: React.ReactNode;
}

function UnauthorizedOverlay({ children }: UnauthorizedOverlayProps) {
  return (
    <Box
      w="100%"
      h="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={[0, 0, 5]}
    >
      <Image src={CoveyLogo} height={['auto', '40px', '40px']} width={['80vw', 'auto', 'auto']} />
      {children}
    </Box>
  );
}

export default UnauthorizedOverlay;
