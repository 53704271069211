import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function Delete(props: IconProps) {
  return (
    <Icon width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_367_11095"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="19"
        height="18"
      >
        <rect x="0.5" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_367_11095)">
        <path
          d="M5.75 15.75C5.3375 15.75 4.98438 15.6031 4.69062 15.3094C4.39687 15.0156 4.25 14.6625 4.25 14.25V4.5C4.0375 4.5 3.85938 4.42813 3.71562 4.28438C3.57187 4.14063 3.5 3.9625 3.5 3.75C3.5 3.5375 3.57187 3.35938 3.71562 3.21563C3.85938 3.07188 4.0375 3 4.25 3H7.25C7.25 2.7875 7.32187 2.60938 7.46562 2.46562C7.60938 2.32187 7.7875 2.25 8 2.25H11C11.2125 2.25 11.3906 2.32187 11.5344 2.46562C11.6781 2.60938 11.75 2.7875 11.75 3H14.75C14.9625 3 15.1406 3.07188 15.2844 3.21563C15.4281 3.35938 15.5 3.5375 15.5 3.75C15.5 3.9625 15.4281 4.14063 15.2844 4.28438C15.1406 4.42813 14.9625 4.5 14.75 4.5V14.25C14.75 14.6625 14.6031 15.0156 14.3094 15.3094C14.0156 15.6031 13.6625 15.75 13.25 15.75H5.75ZM13.25 4.5H5.75V14.25H13.25V4.5ZM8 12.75C8.2125 12.75 8.39062 12.6781 8.53438 12.5344C8.67812 12.3906 8.75 12.2125 8.75 12V6.75C8.75 6.5375 8.67812 6.35938 8.53438 6.21562C8.39062 6.07187 8.2125 6 8 6C7.7875 6 7.60938 6.07187 7.46562 6.21562C7.32187 6.35938 7.25 6.5375 7.25 6.75V12C7.25 12.2125 7.32187 12.3906 7.46562 12.5344C7.60938 12.6781 7.7875 12.75 8 12.75ZM11 12.75C11.2125 12.75 11.3906 12.6781 11.5344 12.5344C11.6781 12.3906 11.75 12.2125 11.75 12V6.75C11.75 6.5375 11.6781 6.35938 11.5344 6.21562C11.3906 6.07187 11.2125 6 11 6C10.7875 6 10.6094 6.07187 10.4656 6.21562C10.3219 6.35938 10.25 6.5375 10.25 6.75V12C10.25 12.2125 10.3219 12.3906 10.4656 12.5344C10.6094 12.6781 10.7875 12.75 11 12.75Z"
          fill="#DC3545"
        />
      </g>
    </Icon>
  );
}
